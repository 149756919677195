/** @jsx jsx */
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { Container, Flex, jsx } from "theme-ui"

const PrivacyPolicyPage = ({ data }) => {
  const { policyContent, seoMetaTags } = data.datoCmsPrivacyPolicy

  const title = seoMetaTags.tags.find(
    t => !!t.tagName && /title/.test(t.tagName)
  )

  return (
    <Layout>
      <SEO datoMetaTags={seoMetaTags}>
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/de/privacy"}
          hrefLang={"de"}
        />
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/fr/privacy"}
          hrefLang={"fr"}
        />
        <meta property="og:site name" content={title.content} />
      </SEO>
      <Flex as={"section"} sx={{ pt: 10 }}>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: policyContent }} />
        </Container>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyPolicy($language: String!) {
    datoCmsPrivacyPolicy(locale: { eq: $language }) {
      policyContent
      seoMetaTags {
        id
        tags
      }
    }
  }
`

export default PrivacyPolicyPage
